<template>
    <div class="accountSecurity" style="background: #d1e1f9;">
        <el-row  style="margin: 0 auto;  background-color: #d1e1f9;width: 1350px;padding: 20px">
            <el-col :span="6">
                <contentLeft></contentLeft><!-- 会员资料显示左边部分，公共部分 -->
            </el-col>
            <el-col :span="18">
                <div class="accountSecurity-content-right">
                    <div class="con-base">
                        <b><span>|</span>基本信息</b>
                    </div>
                    <div class="Safetylevel-">
                        <div class="Safetylevel">
                            <div>*安全等级
                                <el-progress :text-inside="true" :stroke-width="18" :percentage="70" style="display: inline-block;width: 197px;"></el-progress></div>
                            <button>自助解封</button>
                        </div>
                    </div>
                    <div class="person--information">
                        <div class="personinformation">
                            <b>*登录密码</b>
                            <span>强烈建议经常修改密码，提升账号安全等级</span>
                            <button>修改密码</button>
                        </div>
                    </div>
                    <div class="person--information">
                        <div class="personinformation">
                            <b>*保密手机 <a>*137****111</a></b>
                            <span>密保手机可用于登录，找回密码或者其他安全验证</span>
                            <button>更换手机号</button>
                        </div>
                    </div>
                    <div class="person--information">
                        <div class="personinformation">
                            <b>*密保邮箱 <a>*未绑定邮箱 </a></b>
                            <span>密保邮箱可用于登录，找回密码或其他安全验证</span>
                            <button>绑定邮箱</button>
                        </div>
                    </div>
                    <div class="person--information">
                        <div class="personinformation">
                            <b>*绑定微信 <a>*未绑定</a></b>
                            <span>绑定微信，接收登录及安全风险通知，保障账号安全</span>
                            <button>绑定微信</button>
                        </div>
                    </div>
                    <div class="person--information">
                        <div class="personinformation">
                            <b>*绑定QQ <a>*未绑定</a></b>
                            <span>为保障账号安全和稳定性，请绑定手机和微信</span>
                            <button>绑定QQ</button>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import contentLeft from '@/components/contentLeft.vue'
export default {
  name: "VipPrivilge",
  components: {
    contentLeft
  },
};
</script>
<style scoped>
.accountSecurity-center-text{
  background: #d1e1f9;
}
.accountSecurity-content{
  width:1350px;
  background-color: #d1e1f9;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
}
.accountSecurity-content-right{
background-color: #fff;
overflow: hidden;
}
.con-base{
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #efeff0;;

}
.con-base b{
margin-left: 50px;
}
.con-base b span{
  color: #007aff;
}
.Safetylevel-{
border-bottom: 1px solid #efeff0;
}
.Safetylevel{
height: 95px;
line-height: 95px;
/* background: red; */
display: flex;
justify-content: space-between;
align-items: center;
margin-left: 50px;
margin-right: 50px;
font-size: 14px;
}
.Safetylevel b span{
  display: block;
  background-color: #007aff;
  width: 50px;
  height: 10px;
}
.Safetylevel button{
border: 1px solid #007aff ;
width: 101px;
height: 39px;
background-color: #fff;
font-size: 14px;
color: #007aff ;
border-radius: 5px;
}
.person--information{
border-bottom: 1px solid #efeff0;
}
.personinformation{
height: 95px;
line-height: 95px;
display: flex;
justify-content: space-between;
align-items: center;
margin-left: 50px;
margin-right: 50px;
font-size: 14px;
}
.personinformation span{
font-size: 12px;
color:#707070
}
.personinformation button{
border: 1px solid #007aff ;
width: 100px;
height: 39px;
background-color: #fff;
font-size: 14px;
color: #007aff ;
border-radius: 5px;
}
</style>
