<template>
  <div class="content-left">
    <div class="content-left-top">
      <el-upload
              class="avatar-uploader"
              action="https://res.bimshows.cn/platform/uploadfile"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
      >
        <img  :src="common.retun_touxiangimg(session.headpath)" class="avatar" alt="头像" title="点击修改"  style="height:50px;widht:50px;margin:20px auto;">
      </el-upload>
      <img   src=""  />
      <p class="ID">
        ID:{{session.id}}
      </p>
      <br/>
      <p>普通用户</p>
    </div>
    <div class="content-left-bottom">
      <ul>
        <li :class="type_data==2?'after_li':''" @click="ziliao">个人资料</li>
        <!-- <li :class="type_data==3?'after_li':''" @click="linkVip">企业特权</li> -->
        <li :class="type_data==4?'after_li':''" @click="linkwallet">我的钱包</li>
        <!--<li @click="linkaccount">账号安全</li>-->
        <li :class="type_data==5?'after_li':''" @click="linkinfrom">我的通知</li>
        <!--<li :class="type_data==6?'after_li':''" @click="linkspace">空间内存</li>-->
      </ul>
    </div>
  </div>
</template>

<script>
    import { requestAccount_info,requestAccount } from "../api/request";

    export default {
    data () {
        return {
            typeCon: true,
            session:"",
            type_data:0,
            imageUrl: ''
        }
    },
    props:{
      type:{
        type:String
      }
    },
  methods: {
      isLogin1:function (){
          let session=this.Account.GetSession()
          if(session!=null){
              this.session=session;
          }
      },
    ziliao(){
     this.$router.push("/About")
    },
    linkVip() {
          this.$router.push("/VipPrivilge")
    },
    linkwallet(){
          this.$router.push('/MyWallet')
    },
    linkaccount(){
          this.$router.push('/AccountSecurity')
    },
    linkinfrom(){
          this.$router.push('/MyInform')
    },
    linkspace(){
          this.$router.push('/MemorySpace')
    },
      handleAvatarSuccess(res, file) {
          console.log(res)
          this.imageUrl = res.json.Url;
          let info1={
              nickname:this.session.nickname,
              phone:this.session.phone,
              headpath:res.json.Url,
              gender:this.session.gender
          },data_this=this;
          requestAccount_info(info1).then(res=>{
              if(res.code==0){
                  data_this.$message({
                      message: "修改成功",
                      type: "success",
                  });
                  requestAccount().then(res => {
                      localStorage.setItem("session",JSON.stringify(res.ret));
                      data_this.isLogin1()
                      setTimeout(function () {
                          history.go(0)
                      },1000)

                  })
              }else {
                  data_this.$message({
                      message: res.ret,
                      type: "error",
                  });
              }
          })
      },
      beforeAvatarUpload(file) {
          const isJPG = file.type == 'image/jpeg' ||'image/png';
          const isLt2M = file.size / 1024 / 1024 < 2;

          if (!isJPG) {
              this.$message.error('上传头像图片只能是 JPG . PNG格式!');
          }
          if (!isLt2M) {
              this.$message.error('上传头像图片大小不能超过 2MB!');
          }
          return isJPG && isLt2M;
      }
  },
    mounted () {
        this.type_data=this.type;
        this.isLogin1();
    }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.content-left {
  background-color: #fff;
  width: 300px;
  height: 600px;
  /* float: left; */
  margin-right: 12px;
  /* margin-left: 100px; */
  /* margin: 0 auto; */
}
.content-left-top {
  border-bottom: 1px solid #efeff0;
  text-align: center;
  padding-bottom: 20px;
}
.content-left-top p {
  color: #6e6f70;
  font-size: 14px;
}
.content-left-bottom ul li {
  padding: 20px 0;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  text-indent: 13px;
  border-bottom: 1px solid #CCCCCC;
}
.content-left-bottom ul li:hover{
  background-color: #c43e1b;
  color: white;
}
.after_li{
  background-color: #c43e1b !important;
  color: white !important;
}
.ID {
  font-size: 14px;
  color: #282929;
}
</style>
